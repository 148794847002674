import { gql, useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';

import { dateFormat } from '@/core/time';
import { darkGreen } from '@/theme/colors';

import Button from '../Button';
import ColumnSection from '../ColumnSection/ColumnSection';
import FAIcon from '../Icon/FAIcon';
import s from './ActivityLog.module.scss';
import activityConfig from './activityConfig';

const FETCH_ACTIVITIES = gql(/* GraphQL */ `
  query fetchActivities($cursor: String, $opts: [ActivityOptsType!]!) {
    activities(after: $cursor, opts: $opts) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          user {
            id
            name
            html_url
          }
          object_type
          object_id
          action
          timestamp
          context {
            ... on RawActivityContext {
              raw
            }
            ... on UserActivityContext {
              user {
                id
                name
                html_url
              }
            }
            ... on NetworkExpertActivityContext {
              team_note
              created_by {
                id
                name
                html_url
              }
              from_group {
                id
                name
                html_url
              }
              add_method
              profile {
                id
                name
                html_url
              }
              network {
                id
                name
              }
            }
            ... on ConsultationActivityContext {
              raw
              consultation {
                id
                html_url
              }
              requester {
                id
                name
                html_url
              }
              expert {
                id
                name
                html_url
              }
              expert_request {
                id
                name
                html_url
              }
              group {
                id
                name
                html_url
              }
            }
            ... on CandidateActivityContext {
              raw
              expert {
                id
                name
                html_url
              }
              expert_request {
                id
                name
                html_url
              }
              group {
                id
                name
                html_url
              }
              client {
                id
                name
                html_url
              }
              research_manager {
                id
                name
                html_url
              }
            }
            ... on ProfileActivityContext {
              raw
              updated_by {
                id
                name
                html_url
              }
            }
          }
        }
      }
    }
  }
`);

function acceptedActivity(activity: any) {
  const configPresent = !!activityConfig(activity.node);
  if (!configPresent) {
    console.warn('no activity log config for action', activity.object_type, activity.action);
    return false;
  }
  return configPresent;
}

function filterOlderNotes(activities: any[]) {
  const seenNotes = {};
  return [...activities]
    .sort((a: any, b: any) => -moment(a.timestamp).diff(b.timestamp)) // most recent first
    .map((actvt: any) => {
      // if activity is not a candidate update, preserve it
      if (actvt.action !== 'candidate_update') return actvt;

      const {
        request_id: rid,
        client_note: cnote,
        rm_note: rnote,
        expert_note: enote,
      } = actvt.context || {};

      // if activity has no note, preserve it
      if (!cnote && !rnote && !enote) return actvt;

      // otherwise, keep just the most recent one for each request x author pair
      const notes = [cnote, rnote, enote].filter(Boolean);
      const noteKey = (note: any) => `${rid}#${note.author_id}`;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const shouldPreserve = notes.some((n) => !seenNotes[noteKey(n)]); // check
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      notes.forEach((n) => (seenNotes[noteKey(n)] = true)); // record seen
      return shouldPreserve ? actvt : null;
    })
    .filter(Boolean);
}

function groupByDate(activities: any) {
  return Object.entries(
    activities.reduce(
      // group activities by date
      (groups: any, actvt: any) => {
        const groupKey = moment(actvt.node.timestamp).format('YYYY-MM-DD');
        return {
          ...groups,
          [groupKey]: [...(groups[groupKey] || []), actvt.node],
        };
      },
      {}
    )
  )
    .map(
      // format date for each date group and sort activities
      ([date, activities]) => ({
        date,
        formatedDate: moment(date).format(dateFormat),
        activities:
          // activities sorted by timestamp, most recent first
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          [...activities].sort((a: any, b: any) => -moment(a.timestamp).diff(b.timestamp)),
      })
    )
    .sort(
      // date groups sorted by date, most recent first
      (a, b) => -moment(a.date).diff(b.date)
    );
}

interface ActivityItemProps {
  activity: any;
  loading: boolean;
}

export interface ActivityOpts {
  objectId: string;
  objectType: string;
  actions: string[];
}

function ActivityItem({ activity, loading }: ActivityItemProps) {
  const config = activityConfig(activity);

  if (!config) {
    return (
      <div className={`${s.item} ${s.missing}`}>
        Missing action config for {activity.object_type}_{activity.action}
      </div>
    );
  }

  const { renderer: ActivityActionRenderer } = config;

  return (
    <div className={s.item}>
      <FAIcon size={16} icon={config.icon} style={{ color: darkGreen, marginRight: 5 }} />
      <div>
        <ActivityActionRenderer activity={activity} loading={loading} />
      </div>
      <hr />
    </div>
  );
}

function ActivityGroup({ dateGroup, loading }: any) {
  return (
    <div className={s.activityGroup}>
      <div className={s.date}>{dateGroup.formatedDate}</div>
      {dateGroup.activities.map((actvt: any) => (
        <ActivityItem key={actvt.id} activity={actvt} loading={loading} />
      ))}
    </div>
  );
}

interface ActivityLogProps {
  activityOpts: ActivityOpts[];
}

const ActivityLog: React.FC<ActivityLogProps> = ({ activityOpts }) => {
  const fetchMoreOpts = useMemo(
    () =>
      activityOpts.flatMap(({ objectId, objectType, actions }) =>
        actions.map((action) => ({
          object_id: objectId,
          object_type: objectType,
          action,
        }))
      ),
    [activityOpts]
  );

  console.log('LOG: ', JSON.stringify(fetchMoreOpts));
  const { data, loading, fetchMore } = useQuery(FETCH_ACTIVITIES, {
    variables: {
      opts: fetchMoreOpts,
    },
  });

  const edges = data?.activities?.edges || [];
  const lastCursor = edges && (edges.length > 0 ? edges.slice(-1)[0].cursor : null);
  const activities = {
    data: edges || [],
    nextCursor: data?.activities?.pageInfo?.hasNextPage ? lastCursor : null,
  };

  const onMore = async () => {
    fetchMore({
      variables: {
        cursor: activities.nextCursor,
      },
      updateQuery(previousData, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return previousData;
        }

        return {
          ...fetchMoreResult,
          activities: {
            ...fetchMoreResult.activities,
            edges: [...previousData.activities.edges, ...fetchMoreResult.activities.edges],
          },
        };
      },
    });
  };

  const getFilteredActivities = function () {
    if (loading) {
      return [];
    }

    return filterOlderNotes(activities.data.filter(acceptedActivity) || []);
  };

  const filteredActivities = getFilteredActivities();
  if (!filteredActivities || !filteredActivities.length) return null;

  const dateGroups = groupByDate(filteredActivities);

  return (
    <ColumnSection title="Activity">
      {!dateGroups.length && <div className={s.noActivities}>No activity</div>}
      {dateGroups.map((group) => (
        <ActivityGroup key={group.date} dateGroup={group} loading={loading} />
      ))}
      {activities?.nextCursor && (
        <div className={s.loadMore}>
          <Button onClick={onMore}>Load More</Button>
        </div>
      )}
    </ColumnSection>
  );
};

export default ActivityLog;
