import makeStyles from '@mui/styles/makeStyles';

import Message from '@/messaging/components/Message';
import { lightBlue } from '@/theme/colors';

import LongText from '../LongText';
import UserRef from './UserRef';

const useStyles = makeStyles(() => ({
  message: {
    marginTop: 10,
    marginBottom: 10,
  },
  bubble: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    border: `2px solid ${lightBlue}`,
    padding: '10px !important',
  },
}));

function AdditionalInformationActivity({
  activity: {
    context: {
      updated_by,
      raw: { value },
    },
  },
}: any) {
  const s = useStyles();

  return (
    <div>
      <div>
        <UserRef user={updated_by} link /> updated additional information
      </div>
      <Message className={s.message} bubbleClassName={s.bubble} user={updated_by}>
        <LongText text={value} />
      </Message>
    </div>
  );
}

export default AdditionalInformationActivity;
