import moment from 'moment-timezone';

import { EngagementType, ExpertRequestType } from '@/__generated__/graphql';
import Duration from '@/core/duration';
import { TimeRange } from '@/core/time';
import { Viewer } from '@/core/viewer';
import { darkBlue, darkGreen, red500 } from '@/theme/colors';

import { Consultation } from './components/Consultation';

export interface Expert {
  id: string;
  name: string;
  phone: string;
}

export const chargeLabels = {
  booking_fee: 'Booking Fee',
  expert_time: 'Expert Time',
  transcription_fee: 'Transcription Fee',
  service_fee: 'Service Fee',
  concierge_fee: 'Concierge Fee',
  active_user_fee: 'Active User Fee',
  retainer_fee: 'Retainer Fee',
};

export function getStateDescription(consultation: Consultation): {
  icon: string;
  iconSet?: string;
  text: string;
  color: string;
} {
  if (consultation.external) {
    return {
      icon: 'external-link-square',
      text: 'External Consultation',
      color: darkGreen,
    };
  }

  const target =
    consultation.engagement_type === EngagementType.WrittenResponse ? 'Consultation' : 'Call';

  const states = {
    negotiating_expert_time: { text: 'Awaiting Confirmation', color: darkBlue },
    negotiating_client_time: { text: 'Awaiting Confirmation', color: darkBlue },
    awaiting_expert_review: {
      text: 'Awaiting Written Review From Expert',
      color: darkBlue,
    },
    awaiting_client_accept: {
      text: 'Awaiting Client Approval',
      color: darkBlue,
    },
    denied: { text: `${target} Denied`, color: red500 },
    expired: { text: `${target} Expired`, color: red500 },
    canceled: { text: `${target} Canceled`, color: red500 },
    client_rejected: { text: `${target} Rejected`, color: red500 },
    incomplete: { text: `${target} Incomplete`, color: red500 },
    confirmed: { text: `${target} Confirmed`, color: darkGreen },
    finalizing: { text: `${target} Finalizing`, color: darkGreen },
    completed: { text: `${target} Completed`, color: darkGreen },
  };

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return { icon: 'clock', iconSet: 'far', ...states[consultation.state] };
}

export function shouldAllowJoinCall(consultation: {
  state: string;
  starts_at: moment.MomentInput;
}): boolean {
  // confirmation must be in confirmed state
  if (consultation.state !== 'confirmed') return false;

  // must be in call window (t-1 until t+12 hours)
  const now = moment();
  const callTime = moment(consultation.starts_at);
  const callWindowStart = moment(callTime).add(-1, 'hours');
  const callWindowEnd = moment(callTime).add(12, 'hours');
  if (now.isBefore(callWindowStart)) return false;
  if (now.isAfter(callWindowEnd)) return false;

  // allow joining call
  return true;
}

export function isTooEarlyToJoinCall(consultation: {
  state: string;
  starts_at: moment.MomentInput;
}): boolean {
  // confirmation must be in confirmed state
  if (consultation.state !== 'confirmed') return false;

  // must be before call window (t-1 hour)
  const now = moment();
  const callWindowStart = moment(consultation.starts_at).add(-1, 'hours');
  if (now.isAfter(callWindowStart)) return false;

  // it is too early to join call
  return true;
}

export function getSuggestedTime(
  start: moment.Moment,
  timezone1: string,
  timezone2: string
): moment.Moment | undefined {
  const range1 = new TimeRange(
    moment.tz(start, timezone1).startOf('day').hour(8),
    moment.tz(start, timezone1).startOf('day').hour(22)
  );
  const range2 = new TimeRange(
    moment.tz(start, timezone2).startOf('day').hour(8),
    moment.tz(start, timezone2).startOf('day').hour(22)
  );

  const overlap1 = range1.overlap(range2);
  if (overlap1 && start.isSameOrAfter(overlap1.start) && start.isBefore(overlap1.end)) return start;

  // Depending on the timezones we have to check on the next day for overlap
  if (range1.isBefore(range2)) range1.add(1, 'd');
  else range2.add(1, 'd');

  const overlap2 = range1.overlap(range2);
  if (overlap2 && start.isSameOrAfter(overlap2.start) && start.isBefore(overlap2.end)) return start;

  if (overlap1 && start.isBefore(overlap1.start)) return overlap1.start;

  if (overlap2 && start.isBefore(overlap2.start)) return overlap2.start;

  if (overlap1) return overlap1.start.add(1, 'd');

  if (overlap2) return overlap2.start.add(1, 'd');
}

function sortDates(a?: moment.Moment, b?: moment.Moment) {
  return !a
    ? Number.MAX_VALUE
    : !b
      ? Number.MIN_VALUE
      : a.toDate().getTime() - b.toDate().getTime();
}

type SuggestedTimesOptions = {
  t1?: string | null | undefined;
  t2?: string | null | undefined;
  hourOffset?: moment.DurationInputArg1;
  hourInterval?: moment.DurationInputArg1;
};
export function getSuggestedTimes({
  t1 = Intl.DateTimeFormat().resolvedOptions().timeZone,
  t2 = Intl.DateTimeFormat().resolvedOptions().timeZone,
  hourOffset,
  hourInterval,
}: SuggestedTimesOptions): moment.Moment[] {
  const timezone1 = t1 || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone2 = t2 || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const minutes = moment().add(10, 'm').minutes();
  const start = moment()
    .add(hourOffset, 'h')
    .minutes(Math.ceil(minutes / 5) * 5)
    .seconds(0)
    .milliseconds(0);
  const time1 = getSuggestedTime(start, timezone1, timezone2);
  const time2 =
    time1 && getSuggestedTime(moment(time1).add(hourInterval, 'h'), timezone1, timezone2);
  const time3 =
    time2 && getSuggestedTime(moment(time2).add(hourInterval, 'h'), timezone1, timezone2);
  return [time1, time2, time3].sort(sortDates).map((t) => moment.tz(t, timezone1));
}

type ValidationError = Record<string, string | Array<string | undefined>> | undefined;
export function validate(viewer: Viewer) {
  return (values: { dates: moment.MomentInput[]; duration: string }): ValidationError => {
    const errors: ValidationError = {};

    const dates = values.dates.filter(Boolean);

    const dateErrors =
      values.dates.filter(Boolean).length === 0
        ? ['Required']
        : values.dates.map((date: moment.MomentInput) => {
            if (!date) return;

            const now = moment().seconds(0).milliseconds(0);
            const suggested = moment(date).seconds(0).milliseconds(0);

            if (suggested.isBefore(now)) {
              return 'Must be a future time';
            }

            const timeUntilStart = new TimeRange(now, suggested);
            if (viewer.admin && timeUntilStart.duration().minutes() < 10) {
              return 'Consultation must be scheduled at least 10 minutes in advance';
            }

            const alreadySuggested = dates
              .filter((d) => d !== date)
              .some((d) => {
                const start = moment(d).seconds(0).milliseconds(0);
                return suggested.isSame(start);
              });

            if (alreadySuggested) {
              return 'Time has already been suggested';
            }
          });

    if (dateErrors.length > 0) {
      errors.dates = dateErrors;
    }

    if (!values.duration || values.duration === '0') {
      errors.duration = 'Required';
    }

    return Object.keys(errors).length > 0 ? errors : undefined;
  };
}

export function minimumTimeNotice(dates: moment.MomentInput[]): boolean {
  const now = moment().seconds(0).milliseconds(0);
  return dates.some((date: moment.MomentInput) => {
    const suggested = moment(date).seconds(0).milliseconds(0);
    const duration = new Duration(suggested.diff(now));
    return duration.hours() >= 12;
  });
}

export function isDurationEmpty(duration: string): boolean {
  if (!duration) return true;
  return new Duration(duration).isEqualTo(0);
}

export function getUser(
  viewer: Viewer,
  userContext: string,
  consultation: Consultation,
  adminViewRequester: boolean
): Expert | null {
  const { expert, requester } = consultation;

  const isExpert = expert && viewer.id === expert.id;
  const isAdminContext = userContext === 'admin';

  return isExpert || (isAdminContext && adminViewRequester)
    ? {
        id: requester?.id || '',
        name: requester?.name || consultation.requester_name || 'Confidential client',
        phone: requester?.phone || '',
      }
    : {
        id: expert?.id || '',
        name: expert?.name || consultation.expert_name || 'Expert not available',
        phone: expert?.phone || '',
      };
}

export function getUserName(
  viewer: Viewer,
  userContext: string,
  consultation: Consultation,
  adminViewRequester: boolean
): string {
  const { expert, requester } = consultation;
  const isExpert = expert && viewer.id === expert.id;
  const isAdminContext = userContext === 'admin';

  const requesterName = requester?.name || consultation.requester_name || 'Confidential client';
  const expertName = expert?.name || consultation.expert_name || 'Expert not available';
  return isExpert || (isAdminContext && adminViewRequester) ? requesterName : expertName;
}

export function parseDuration(duration: string | number): Duration {
  return typeof duration === 'string' ? Duration.parse(duration) : new Duration(duration);
}

export function formatDuration(duration: string | number): string {
  if (!duration) return '';

  const d = parseDuration(duration);
  const hours = d.hours();
  const minutes = hours > 0 ? d.minutes() % 60 : d.minutes();
  if (hours === 1 && minutes === 0) return '60min';
  // @ts-expect-error TS(2447): The '&' operator is not allowed for boolean types.... Remove this comment to see the full error message
  if ((hours > 0) & (minutes > 0)) return `${hours}hr ${minutes}min`;
  if (hours > 0) return `${hours}hr`;
  if (minutes > 0) return `${minutes}min`;
  const seconds = d.seconds();
  if (seconds > 0) return `${seconds}sec`;
  return '';
}

export function calculateExpertCredits(
  creditRate: number,
  duration: string,
  engagementType: EngagementType
): number {
  if (engagementType === EngagementType.Opportunity) return 0;
  if (engagementType === EngagementType.WrittenResponse) return 300;
  const rate = creditRate || 0;
  const minutes = Duration.parse(duration).minutes();
  return (rate * minutes) / 60;
}

export function canRequestConsultation(
  user: Viewer['profile'],
  profile?: { can_request_consultation: boolean }
): boolean {
  if (!user) return false;

  return (
    user?.expert_state === 'active' && (!profile || profile.can_request_consultation) // check profile when passed
  );
}

export const recordingTypes = Object.freeze({
  audioOnly: 'audio_only',
  chatFile: 'chat_file',
  sharedScreenWithSpeakerView: 'shared_screen_with_speaker_view',
  sharedScreenWithGalleryView: 'shared_screen_with_gallery_view',
  sharedScreen: 'shared_screen',
  activeSpeaker: 'active_speaker',
  galleryView: 'gallery_view',
});

export const fileTypes = {
  mp4: 'MP4',
  mp3: 'MP3',
  m4a: 'M4A',
  chat: 'CHAT',
  transcript: 'TRANSCRIPT',
};

export const isVideoType = (type: string): boolean => [fileTypes.mp4].includes(type);

export const isAudioType = (type: string): boolean => [fileTypes.mp3, fileTypes.m4a].includes(type);

export const isAudioOrVideoType = (type: string): boolean => isAudioType(type) || isVideoType(type);

export function getEngagementType(er: {
  er_type: ExpertRequestType | EngagementType;
}): EngagementType {
  switch (er?.er_type) {
    case ExpertRequestType.WrittenReview:
      return EngagementType.WrittenResponse;
    case ExpertRequestType.NewHire:
    case ExpertRequestType.ConsultingProject:
      return EngagementType.Opportunity;
  }
  return EngagementType.Consultation;
}
