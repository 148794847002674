import { useApolloClient } from '@apollo/client';
import MuiTextField from '@mui/material/TextField';
import { Field, useFormik } from 'formik';
import { Moment } from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { EngagementType } from '@/__generated__/graphql';
import { notify } from '@/actions/ui';
import Select from '@/components/Select/Select';
import FormikForm from '@/componentsv2/FormikForm';
import { Trigger, addConsultation, updateConsultation } from '@/consultation/store';
import { APIError } from '@/core/api';
import { openFileDialog, presignAttachmentURL } from '@/core/attachment';
import { Viewer } from '@/core/viewer';
import { RootState } from '@/store';
import { primary } from '@/theme/colors';

import Button from '../Button/Button';
import DateTimePicker from '../DateTimePicker';
import Dialog, { DialogProps } from '../Dialog';
import FAIcon from '../Icon/FAIcon';
import MaterialIcon from '../Icon/MaterialIcon';
import MediaPlayer from '../MediaPlayer';
import s from './EditExternalConsultation.module.scss';

type ExpertRequest = {
  disclosure: string;
  er_type: EngagementType;
  html_url: string;
  id: string;
  name: string;
  project: object;
  state: string;
};

const ChooseFile = ({ label, onClick, disabled, meta: { touched, error } }: any) => (
  <div className={s.upload}>
    <Button size="small" label={label} onClick={onClick} disabled={disabled} />
    {error && touched && <div className={s.error}>{error}</div>}
  </div>
);

interface FormData {
  recording_url: string;
  recording_filename: string;
  recording_file_size: number;
  started_at: string;
  requester_name: string;
  expert_name: string;
  expert_request_id: string;
  group_id: string;
  engagement_type: EngagementType;
}

function validate(values: FormData) {
  const errors: Partial<Record<keyof FormData, string>> = {};

  if (!values.recording_url) {
    errors.recording_url = 'Required';
  }

  if (!values.started_at) {
    errors.started_at = 'Required';
  }

  if (!values.requester_name) {
    errors.requester_name = 'Required';
  }

  if (!values.expert_name) {
    errors.expert_name = 'Required';
  }

  if (!values.group_id && !values.expert_request_id) {
    const errorMessage = 'Either Expert Request or Group is Required';
    errors.expert_request_id = errorMessage;
    errors.group_id = errorMessage;
  }

  return errors;
}

interface EditExternalConsultationProps extends DialogProps {
  open?: boolean;
  consultation?: any;
  initialValues?: FormData;
  onSubmit?: () => void;
  onClose?: () => void;
  viewer: Viewer;
  expertRequests: {
    edges: ExpertRequest[];
  };
  expertRequest?: ExpertRequest;
  notify: (message: string, type: 'error' | 'success') => void;
  addConsultation: (values: FormData) => Promise<any>;
  updateConsultation: (values: any) => Promise<any>;
}

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
    expertRequests: state.expertRequests.open,
    expertRequest: state.expertRequests.open?.edges?.find(
      (e: any) => e.node.id === state.form?.addExternalConsultation?.values?.expert_request_id
    ),
  }),
  {
    notify,
    addConsultation,
    updateConsultation,
  }
);

const EditExternalConsultation = ({
  open,
  consultation,
  initialValues,
  onSubmit,
  addConsultation,
  updateConsultation,
  notify,
  onClose,
  viewer,
  expertRequests,
  expertRequest,
}: ConnectedProps<typeof connector> & EditExternalConsultationProps) => {
  const navigate = useNavigate();
  const graphql = useApolloClient();

  const adding = useMemo(() => !consultation, [consultation]);
  const [submitting, setSubmitting] = useState(false);
  const [recordingUrl, setRecordingUrl] = useState(consultation?.recording_url || '');

  // const setDuration = (audio: any) => {
  //   const duration = audio && audio.duration && `${audio.duration}s`;
  //   if (recordingDuration === duration) {
  //     change('recording_duration', duration);
  //   }
  // };

  const handleSubmitFn = useCallback(
    async (values: FormData) => {
      setSubmitting(true);

      if (adding) {
        try {
          const consultation = await addConsultation(values);
          setSubmitting(false);
          navigate(`/consultation/${consultation.id}`);
        } catch (err: unknown) {
          if (
            err instanceof APIError &&
            err.message.startsWith(
              'GraphQL Error: no billing account associated with group or expert request'
            )
          ) {
            notify('Unable to find billing account for consultation.', 'error');
            return;
          }
          notify('Error when adding external consultation.', 'error');
          throw err;
        } finally {
          setSubmitting(false);
        }
      } else {
        try {
          await updateConsultation({
            id: consultation.id,
            trigger: Trigger.consultationPage,
            ...values,
          });
          if (onSubmit) return onSubmit();
        } finally {
          setSubmitting(false);
        }
      }
    },
    [addConsultation, adding, consultation?.id, navigate, notify, onSubmit, updateConsultation]
  );

  const formik = useFormik({
    initialValues: {
      recording_url: initialValues?.recording_url || '',
      recording_filename: initialValues?.recording_filename || '',
      recording_file_size: initialValues?.recording_file_size || 0,
      started_at: initialValues?.started_at || '',
      requester_name: initialValues?.requester_name || '',
      expert_name: initialValues?.expert_name || '',
      expert_request_id: consultation?.expert_request.id || '',
      group_id: consultation?.group.id || '',
      engagement_type: initialValues?.engagement_type || EngagementType.Consultation,
    },
    validate,
    onSubmit: handleSubmitFn,
  });

  const pickFile = async () => {
    openFileDialog(graphql, {
      accept: ['.mp3', '.mp4', '.m4a'],
      maxSize: 2 * 1024 * 1024 * 1024, // 2 GB
    }).then(async (files) => {
      const file = files[0];
      formik.setFieldValue('recording_filename', file.filename);
      formik.setFieldValue('recording_url', file.url);
      formik.setFieldValue('recording_file_size', file.size);

      const recordingUrl = await presignAttachmentURL(graphql, file.url);
      setRecordingUrl(recordingUrl);
    });
  };

  const handleReset = () => {
    setSubmitting(false);
    setRecordingUrl('');
    formik.resetForm();
    if (onClose) onClose();
  };

  const enterpriseExpertRequests: ExpertRequest[] =
    expertRequests.edges
      ?.filter(
        // @ts-expect-error TS(7031): Binding element 'er' implicitly has an 'any' type.
        ({ node: er }) => er.project?.group?.account_type === 'enterprise'
      )
      .map((e: any) => e.node) || [];

  const enterpriseGroups = viewer.groups?.filter((g) => g.account_type === 'enterprise') || [];
  const expertRequestGroup = expertRequest?.node?.project?.group;
  const singleGroup = expertRequestGroup || (enterpriseGroups.length === 1 && enterpriseGroups[0]);

  const transcribing = !!(
    consultation &&
    !consultation.transcription &&
    consultation.transcription_order
  );
  const transcribed = !!(consultation && consultation.transcription);

  return (
    <Dialog
      open={open}
      onCancel={handleReset}
      onConfirm={formik.submitForm}
      cancelLabel="Cancel"
      confirmLabel={
        submitting
          ? adding
            ? 'Adding...'
            : 'Saving...'
          : adding
            ? 'Add Consultation'
            : 'Save Consultation'
      }
      disableSubmit={submitting || recordingUrl === ''}
      onClose={handleReset}
    >
      <div className={s.header}>
        <MaterialIcon icon={adding ? 'file_upload' : 'mode_edit'} color={primary} size={40} />

        <h4 className={s.title}>
          <span>{adding ? 'Add' : 'Edit'}</span> External Consultation
        </h4>

        {adding && (
          <p className={s.text}>
            Upload an audio or video file from a call made outside of OnFrontiers to get a full
            transcript and search through it alongside your other calls. Allowed file types MP3,
            MP4, and M4A.
          </p>
        )}
      </div>
      <FormikForm value={formik}>
        <ChooseFile
          label={adding ? 'Choose File' : 'Change File'}
          onClick={pickFile}
          disabled={transcribing || transcribed}
          meta={{
            touched: formik.touched.recording_url,
            error: formik.errors.recording_url,
          }}
        />
        {recordingUrl && !transcribed && (
          <MediaPlayer enabled src={recordingUrl} /> //onAudioSync={setDuration} />
        )}
        {(formik.values.recording_filename || transcribed || transcribing) && (
          <div className={s.uploadInstructions}>
            {formik.values.recording_filename && (
              <div className={s.uploaded}>
                <FAIcon icon="check" size={16} /> Uploaded {formik.values.recording_filename}
              </div>
            )}
            {transcribing && (
              <div className={s.instruction}>
                Audio files cannot be changed while transcription is in process
              </div>
            )}
            {transcribed && (
              <div className={s.instruction}>
                Audio files cannot be changed after they have been transcribed
              </div>
            )}
          </div>
        )}
        <Field
          component={DateTimePicker}
          name="started_at"
          placeholder="Date"
          value={formik.values.started_at}
          onChange={(date: Moment) => formik.setFieldValue('started_at', date.toISOString())}
          maxDate={new Date()}
          dateOnly
          buttonClear={false}
          fullWidth={false}
          meta={{
            touched: formik.touched.started_at,
            modified: !!formik.values.started_at,
            error: formik.errors.started_at,
          }}
          timezone={viewer.timezone}
        />
        <div className={s.users}>
          <Field name="requester_name">
            {({ field }: { field: any }) => (
              <MuiTextField
                className="mt-10"
                required
                fullWidth
                label="Interviewer"
                style={{ marginRight: 5 }}
                {...field}
              />
            )}
          </Field>
          <Field name="expert_name">
            {({ field }: { field: any }) => (
              <MuiTextField
                className="mt-10"
                required
                fullWidth
                label="Expert"
                style={{ marginRight: 5 }}
                {...field}
              />
            )}
          </Field>
        </div>
        {enterpriseExpertRequests.length > 0 && (
          <Field name="expert_request_id">
            {({ field }: { field: any }) => {
              const options = enterpriseExpertRequests.map((er) => ({
                value: er.id,
                label: er.name,
              }));
              const value = options.find((o) => o.value === formik.values.expert_request_id);
              return (
                <Select
                  label="Add to Expert Request"
                  options={options}
                  value={value}
                  width="fit-content"
                  menuItemClasses="text-wrap"
                  meta={{
                    touched: formik.touched.expert_request_id,
                    error: formik.errors.expert_request_id,
                    warning: formik.errors.expert_request_id,
                    data: formik.values.expert_request_id,
                    submitError: formik.errors.expert_request_id,
                    submitFailed: formik.submitCount > 0,
                  }}
                  {...field}
                />
              );
            }}
          </Field>
        )}
        <Field name="group_id">
          {({ field }: { field: any }) => {
            const options = enterpriseGroups.map((g) => ({ value: g.id, label: g.name }));
            const value = options.find((o) => o.value === formik.values.group_id);
            return (
              <Select
                id="selectGroup"
                label="Add to Group"
                value={value}
                options={options}
                width="fit-content"
                menuItemClasses="text-wrap"
                disabled={!!singleGroup}
                meta={{
                  touched: formik.touched.group_id,
                  error: formik.errors.group_id,
                  warning: formik.errors.group_id,
                  data: formik.values.group_id,
                  submitError: formik.errors.group_id,
                  submitFailed: formik.submitCount > 0,
                }}
                {...field}
              />
            );
          }}
        </Field>
      </FormikForm>
    </Dialog>
  );
};

export default connector(EditExternalConsultation);
