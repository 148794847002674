import { useSuspenseQuery } from '@apollo/client';
import { Badge } from '@mui/material';
import { MetaData } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { ErrorBoundary } from '@sentry/react';
import cx from 'classnames';
import moment from 'moment-timezone';
import { FC } from 'react';

import { gengql } from '@/__generated__';
import { useAuth } from '@/auth';
import SvgIcon from '@/components/Icon/SvgIcon';
import Picture from '@/components/Picture';
import { ERROR_CODES, hasErrorCode } from '@/core/api';
import { isAnonymousUser } from '@/messaging/sendbird';
import { black, darkGreen, white } from '@/theme/colors';

import { findOtherMember } from '../utils';
import s from './ChannelListItem.module.scss';
import OnlineStatus from './OnlineStatus';
import TypingIndicator from './TypingIndicator';

interface ChannelProps {
  channel: GroupChannel;
  metadata: MetaData;
  isTyping?: boolean;
  selected?: boolean;
  onSelect?: (channel: GroupChannel) => void | Promise<void>;
}

export const getChannelListItemUserOperation = 'getChannelListItemUser';
const GET_USER = gengql(/* GraphQL */ `
  query getChannelListItemUser($id: String!) {
    user(id: $id) {
      id
      profile {
        id
        name
        first_name
        last_name
        picture_url
        html_url
      }
    }
  }
`);

interface ChannelUserProps {
  userId: string;
  isOtherAnonymous: boolean;
  connectionStatus: string;
  lastMessageDate: string;
}

const ChannelUser = ({
  userId,
  isOtherAnonymous,
  connectionStatus,
  lastMessageDate,
}: ChannelUserProps): JSX.Element => {
  const { data } = useSuspenseQuery(GET_USER, {
    variables: {
      id: userId,
    },
    skip: isOtherAnonymous,
  });

  const user = isOtherAnonymous ? null : data?.user;
  const profile = user?.profile;

  return (
    <>
      <div className={s.picture}>
        <Picture user={profile} />
        {connectionStatus === 'online' && <OnlineStatus />}
      </div>

      <div className={s.content}>
        <div>{profile ? profile.name : 'Confidential Client'}</div>
        <div className={s.date}>{lastMessageDate}</div>
      </div>
    </>
  );
};

const UserNotAvailable = ({ lastMessageDate }: { lastMessageDate: string }) => {
  return (
    <>
      <div className={s.picture}>
        <Picture />
      </div>

      <div className={s.content}>
        <div>User not available</div>
        <div className={s.date}>{lastMessageDate}</div>
      </div>
    </>
  );
};

const ChannelListItem: FC<ChannelProps> = ({
  channel,
  metadata,
  isTyping = false,
  selected = false,
  onSelect,
}) => {
  const { viewer } = useAuth();
  if (!viewer.id) throw new Error('Viewer must be logged in');

  const m = findOtherMember(channel.members, viewer.id);
  const isOtherAnonymous = isAnonymousUser(metadata, m.userId);
  const isAnonymous = isAnonymousUser(metadata, viewer.id);

  const handleClick = () => {
    if (onSelect) onSelect(channel);
  };

  const lastMessage = channel.lastMessage;
  const messageDate = lastMessage ? new Date(lastMessage.createdAt) : new Date();
  const format = moment(messageDate).startOf('day').isSame(moment().startOf('day'))
    ? 'h:mma'
    : 'D MMM';
  const lastMessageDate = lastMessage ? moment(messageDate).format(format) : '';

  const unreadCount = channel.unreadMessageCount;

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
      className={cx(s.channel, {
        [s.channelSelected]: selected,
        [s.unreadMessage]: unreadCount > 0,
      })}
    >
      <ErrorBoundary
        fallback={<UserNotAvailable lastMessageDate={lastMessageDate} />}
        beforeCapture={(scope, err) => {
          if (hasErrorCode(err, ERROR_CODES.AUTH_USER_NOT_FOUND)) {
            scope.setTag('skip', true);
          }
        }}
      >
        <ChannelUser
          userId={m.userId}
          isOtherAnonymous={isOtherAnonymous}
          connectionStatus={m.connectionStatus}
          lastMessageDate={lastMessageDate}
        />
      </ErrorBoundary>

      <div className={s.actions}>
        {unreadCount > 0 ? (
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          <Badge
            style={{ padding: 0, verticalAlign: 'middle' }}
            badgeStyle={{
              position: 'initial',
              backgroundColor: selected ? white : darkGreen,
              color: selected ? darkGreen : white,
            }}
            badgeContent={unreadCount}
          />
        ) : isTyping ? (
          <TypingIndicator />
        ) : (
          isAnonymous && (
            <SvgIcon icon="incognito" width={25} height={25} fill={selected ? white : black} />
          )
        )}
      </div>
    </div>
  );
};

export default ChannelListItem;
