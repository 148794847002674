import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import { useCallback, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { gengql } from '@/__generated__';
import { ConsultationState, Query } from '@/__generated__/graphql';
import Button from '@/components/Button/Button';
import ConsultationList from '@/components/ConsultationList';
import EditExternalConsultation from '@/components/EditExternalConsultation';
import EmptyMessage from '@/components/EmptyMessage';
import FAIcon from '@/components/Icon/FAIcon';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import { Select } from '@/components/Select/Select';
import { fetchExpertRequests } from '@/expertrequest/store';
import { RootState } from '@/store';
import { darkGreen } from '@/theme/colors';
import { normalizeSpace } from '@/utils';

import s from './Consultations.module.scss';

const FETCH_CONSULTATIONS = gengql(/* GraphQL */ `
  query fetchConsultations(
    $cursor: String
    $all: Boolean
    $states: [ConsultationState]
    $pageSize: Int!
    $userContext: String!
    $sort: String
    $sortOrder: String
    $searchTitle: String
  ) {
    searchConsultations(
      after: $cursor
      all: $all
      states: $states
      first: $pageSize
      user_context: $userContext
      sort: $sort
      sort_order: $sortOrder
      search_title: $searchTitle
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          html_url
          external
          state
          starts_at
          billing_duration
          recording_duration
          archived
          engagement_type
          permissions
          requester {
            id
            username
            name
            first_name
            last_name
            html_url
            picture_url
          }
          requester_name
          expert {
            id
            username
            name
            first_name
            last_name
            html_url
            picture_url
          }
          expert_name
          expert_request {
            id
            name
          }
        }
      }
    }
  }
`);

const STATE_MAP: Record<string, ConsultationState[]> = {
  AWAITING: [ConsultationState.NegotiatingExpertTime, ConsultationState.NegotiatingClientTime],
  UPCOMING: [
    ConsultationState.Confirmed,
    ConsultationState.AwaitingJoin,
    ConsultationState.AwaitingClientJoin,
    ConsultationState.AwaitingExpertJoin,
    ConsultationState.AwaitingExpertReview,
    ConsultationState.AwaitingClientAccept,
    ConsultationState.InProgress,
    ConsultationState.Finalizing,
  ],
  COMPLETED: [ConsultationState.Completed],
  'EXPIRED/CANCELED': [
    ConsultationState.Canceled,
    ConsultationState.Denied,
    ConsultationState.ClientRejected,
    ConsultationState.Expired,
    ConsultationState.Incomplete,
  ],
};

const STATE_TO_EMPTY_TEXT: Record<string, string> = {
  AWAITING: 'You don’t have any awaiting calls.',
  UPCOMING: 'You don’t have any upcoming calls.',
  COMPLETED: 'You don’t have any completed calls.',
  'EXPIRED/CANCELED': 'You don’t have any expired or canceled calls.',
};

const STATE_TO_TITLE_MAP: Record<string, string> = {
  AWAITING: 'Awaiting',
  UPCOMING: 'Upcoming',
  COMPLETED: 'Completed',
  'EXPIRED/CANCELED': 'Expired/Canceled',
};

const ConsultationPromo = ({ status }: { status: string }) => {
  return (
    <EmptyMessage
      style={{ marginTop: 10 }}
      icon={<FAIcon icon="frown-o" className={s.sadIcon} />}
      title={STATE_TO_EMPTY_TEXT[status]}
      body=""
    />
  );
};

const connector = connect(
  (state: RootState) => ({
    userContext: state.ui.userContext,
    viewer: state.viewer,
  }),
  {
    fetchExpertRequests,
  }
);

const statusOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'AWAITING', label: 'Awaiting' },
  { value: 'UPCOMING', label: 'Upcoming' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'EXPIRED/CANCELED', label: 'Expired / Canceled' },
];

const Consultations = ({
  viewer,
  userContext,
  fetchExpertRequests,
}: ConnectedProps<typeof connector>) => {
  const useFetchConsultations = (state: string, userContext: string, searchTitle: string) => {
    return useQuery(FETCH_CONSULTATIONS, {
      variables: {
        states: STATE_MAP[state],
        pageSize: 10,
        all: true,
        sort: 'created_at',
        sortOrder: 'desc',
        userContext: userContext,
        searchTitle: searchTitle,
      },
    });
  };

  const [erLoading, setErLoading] = useState(false);
  const [externalConsultationOpen, setExternalConsultationOpen] = useState(false);
  const [searchTitle, setSearchTitle] = useState('');
  const [status, setStatus] = useState('ALL');

  const awaitingConsultations = useFetchConsultations('AWAITING', userContext, searchTitle);
  const upcomingConsultations = useFetchConsultations('UPCOMING', userContext, searchTitle);
  const completedConsultations = useFetchConsultations('COMPLETED', userContext, searchTitle);
  const expiredConsultations = useFetchConsultations('EXPIRED/CANCELED', userContext, searchTitle);

  type ConsultationStatus = 'AWAITING' | 'UPCOMING' | 'COMPLETED' | 'EXPIRED/CANCELED';

  const dataSet: Record<ConsultationStatus, any> = useMemo(
    () => ({
      AWAITING: awaitingConsultations,
      UPCOMING: upcomingConsultations,
      COMPLETED: completedConsultations,
      'EXPIRED/CANCELED': expiredConsultations,
    }),
    [awaitingConsultations, upcomingConsultations, completedConsultations, expiredConsultations]
  );

  const handleMore = useCallback(
    (state: ConsultationStatus) => {
      const { fetchMore, data } = dataSet[state];
      if (!data.searchConsultations.pageInfo.hasNextPage) return;
      if (!data.searchConsultations.edges.length)
        throw new Error('last consultation edge not found');
      fetchMore({
        variables: {
          cursor: data.searchConsultations.edges[data.searchConsultations.edges.length - 1].cursor,
        },
        updateQuery: (previousResult: Query, { fetchMoreResult }: { fetchMoreResult: Query }) => {
          if (!fetchMoreResult) return previousResult;
          return {
            searchConsultations: {
              pageInfo: fetchMoreResult.searchConsultations.pageInfo,
              edges: [
                ...previousResult.searchConsultations.edges,
                ...fetchMoreResult.searchConsultations.edges,
              ],
            },
          };
        },
      });
    },
    [dataSet]
  );

  const handleExternalConsultationDialog = useCallback(async () => {
    try {
      setErLoading(true);
      await fetchExpertRequests({ state: 'open' });
      setExternalConsultationOpen(true);
    } finally {
      setErLoading(false);
    }
  }, [fetchExpertRequests]);

  const closeExternalConsultation = useCallback(() => {
    setExternalConsultationOpen(false);
  }, []);

  const belongsToEnterpriseGroup =
    (viewer.groups || []).filter((g) => g.account_type === 'enterprise')?.length > 0;

  return (
    <div>
      <Grid
        justifyContent="flex-end"
        container
        spacing={2}
        sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center' }}
      >
        <Box sx={{ width: { xs: '100%', md: '45%', lg: 230 } }}>
          <Select
            sort={false}
            margin="dense"
            value={status}
            onChange={(event: any) => {
              const { value } = event.target;
              setStatus(normalizeSpace(value));
            }}
            options={statusOptions}
          />
        </Box>
        <Box sx={{ width: { xs: '100%', md: '45%', lg: 300 } }}>
          <TextField
            value={searchTitle ?? ''}
            margin="none"
            fullWidth={false}
            style={{ width: '100%' }}
            onChange={({ target: { value } }) => {
              setSearchTitle(normalizeSpace(value));
            }}
            placeholder="Filter by Project or Expert Request Title"
          />
        </Box>
        {belongsToEnterpriseGroup && (
          <Box
            sx={{
              width: { xs: '100%', lg: 'auto' },
            }}
          >
            <Button
              variant="text"
              className="w-[325px] justify-between"
              submitting={erLoading}
              fontColor={darkGreen}
              style={{ width: '100%' }}
              sx={{ width: '100%' }}
              startIcon={<MaterialIcon icon="file_upload" />}
              onClick={handleExternalConsultationDialog}
            >
              Add External Consultation
            </Button>
          </Box>
        )}
      </Grid>

      {Object.keys(dataSet).map((key) => {
        const { data, loading } = dataSet[key as ConsultationStatus];
        const hasConsultations = (data?.searchConsultations?.edges?.length ?? 0) > 0;

        return (
          <div key={key}>
            {!hasConsultations && !loading && <ConsultationPromo status={key} />}
            {hasConsultations && (status === 'ALL' || status === key) && (
              <ConsultationList
                title={STATE_TO_TITLE_MAP[key]}
                consultations={data?.searchConsultations?.edges?.map((c: any) => c.node)}
                loading={loading}
                pageInfo={data.searchConsultations.pageInfo}
                handleMore={() => handleMore(key as ConsultationStatus)}
              />
            )}
          </div>
        );
      })}

      <EditExternalConsultation
        open={externalConsultationOpen}
        onClose={closeExternalConsultation}
      />
    </div>
  );
};

export default connector(Consultations);
