import {
  ApolloClient,
  ApolloError,
  HttpLink,
  InMemoryCache,
  MaybeMasked,
  NormalizedCacheObject,
  OperationVariables,
  ServerError,
  TypedDocumentNode,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { SentryLink } from 'apollo-link-sentry';
import { DocumentNode, GraphQLFormattedError, print } from 'graphql';

import { getChannelListItemUserOperation } from '@/messaging/components/ChannelListItem';
import { getChatHeaderUserOperation } from '@/messaging/components/ChatHeader';

import ERROR_CODES, { ErrorCode } from './apiErrorCodes';

export { default as ERROR_CODES } from './apiErrorCodes';
export type { ErrorCode } from './apiErrorCodes';

export const OTP_METHODS = {
  SMS: 'sms',
  APP: 'app',
  EMAIL: 'email',
} as const;

export type OtpMethod = (typeof OTP_METHODS)[keyof typeof OTP_METHODS];

export function hasErrorCode(err: unknown, code: ErrorCode): boolean {
  let errors: readonly GraphQLFormattedError[] = [];
  if (err instanceof ApolloError) {
    errors = err.graphQLErrors;
  } else if (err instanceof APIError) {
    errors = err.rawError;
  } else if (err instanceof UnauthorizedError) {
    return err.code === code;
  } else if (Array.isArray(err)) {
    errors = err;
  }

  for (const gqlError of errors) {
    if (gqlError?.extensions?.code === code) return true;
  }

  return false;
}

interface UnauthorizedResponse {
  code: ErrorCode;
  reason?: string;
  remaining_attempts?: number;
  mfa_group_id?: string;
  otp_method?: OtpMethod;
  otp_masked_address?: string;
}

export class UnauthorizedError extends Error {
  isPermissionError: boolean;
  code: ErrorCode;
  remainingAttempts?: number;
  mfaGroupId?: string;
  otpMethod?: OtpMethod;
  otpMaskedAddress?: string;

  constructor(message: string, response: UnauthorizedResponse) {
    super(message);
    this.name = this.constructor.name;
    this.code = response.code;
    this.remainingAttempts = response.remaining_attempts;
    this.mfaGroupId = response.mfa_group_id;
    this.otpMethod = response.otp_method;
    this.otpMaskedAddress = response.otp_masked_address;
    this.isPermissionError = true;
  }
}

export class APIError extends Error {
  isPermissionError: boolean;
  query: string;
  rawError: readonly GraphQLFormattedError[];
  variables?: OperationVariables;

  constructor(
    errors: readonly GraphQLFormattedError[],
    query: string,
    variables?: OperationVariables
  ) {
    const { message } = errors[0];
    super(`GraphQL Error: ${message}`);
    this.rawError = errors;
    this.query = query;
    this.variables = variables;
    this.isPermissionError = hasErrorCode(errors, ERROR_CODES.PERMISSION_NOT_ALLOWED);
  }
}

export function basicAuth(username: string, password: string): string {
  const utf8Bytes = new TextEncoder().encode(`${username}:${password}`);
  const base64String = utf8Bytes.toBase64();
  return `Basic ${base64String}`;
}

export function bearerAuth(token: string): string {
  return `Bearer ${token}`;
}

function handleError<TVariables extends OperationVariables = OperationVariables>(
  err: unknown,
  query: DocumentNode,
  variables?: TVariables
) {
  if (!(err instanceof ApolloError)) {
    throw err;
  }

  if (err.networkError) {
    if ('result' in err.networkError) {
      const serverErr = err.networkError as ServerError;
      switch (serverErr.statusCode) {
        case 401: {
          const resp = serverErr.result as UnauthorizedResponse;
          throw new UnauthorizedError(resp.reason || '', resp);
        }
      }
    }
  }

  const errors = err.graphQLErrors;
  if (errors.length > 0) {
    throw new APIError(errors, print(query), variables);
  }

  throw err;
}

interface GraphQLClientOptions {
  otp?: string;
  extraHeaders?: Record<string, string>;
}

export class GraphQLClient {
  client: ApolloClient<NormalizedCacheObject>;
  url: string;

  constructor(url: string, auth?: string, { otp, extraHeaders = {} }: GraphQLClientOptions = {}) {
    const httpLink = new HttpLink({ uri: `${url}/graphql` });
    // see: https://www.apollographql.com/docs/react/api/link/apollo-link-remove-typename
    const removeTypenameLink = removeTypenameFromVariables();

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...(auth && { authorization: auth }),
          ...(otp && { 'x-onfrontiers-auth-otp': otp }),
          ...extraHeaders,
        },
      };
    });

    this.url = url;
    this.client = new ApolloClient({
      link: from([
        removeTypenameLink,
        authLink,
        new SentryLink({
          shouldHandleOperation: (operation) => {
            const ignoreOperations = [getChannelListItemUserOperation, getChatHeaderUserOperation];
            return !ignoreOperations.includes(operation.operationName);
          },
        }),
        httpLink,
      ]),
      cache: new InMemoryCache(),
      devtools: {
        enabled: import.meta.env.MODE === 'development',
      },
    });
  }

  async query<T = never, TVariables extends OperationVariables = OperationVariables>(
    query: TypedDocumentNode<T, TVariables>,
    variables?: TVariables
  ): Promise<MaybeMasked<T>> {
    try {
      const { data } = await this.client.query({ query, variables });
      return data;
    } catch (err: unknown) {
      handleError(err, query, variables);
      throw err;
    }
  }

  async mutate<T = never, TVariables extends OperationVariables = OperationVariables>(
    query: TypedDocumentNode<T, TVariables>,
    variables?: TVariables
  ): Promise<MaybeMasked<T>> {
    try {
      const { data } = await this.client.mutate({ mutation: query, variables });
      return data!;
    } catch (err: unknown) {
      handleError(err, query, variables);
      throw err;
    }
  }

  fromAuth = (auth: string, options?: GraphQLClientOptions): GraphQLClient =>
    new GraphQLClient(this.url, auth, options);
}
