import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import moment from 'moment-timezone';
import { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { gengql } from '@/__generated__';
import { ConsultationState, EngagementType } from '@/__generated__/graphql';
import { addRegistrant, getJoinInfo } from '@/actions/call';
import { inviteUser } from '@/actions/invitation';
import { track } from '@/actions/tracking';
import { hideMessage, notify } from '@/actions/ui';
import Divider from '@/components/Divider';
import InaccessibleArchived from '@/components/InaccessibleArchived/InaccessibleArchived';
import LayoutPage from '@/components/Layout/LayoutPage';
import MediaQuery from '@/components/MediaQuery';
import ProjectBar from '@/components/ProjectBar';
import { JoinInfo } from '@/conference';
import {
  ALREADY_CANCELED,
  ALREADY_CONFIRMED,
  ALREADY_STARTED,
  NOT_ENOUGH_CREDITS,
  OUTDATED_ERROR,
  Trigger,
  deleteConsultation,
  dismissConsultationReview,
  orderTranscript,
  updateConsultation,
} from '@/consultation/store';
import { ERROR_CODES, hasErrorCode } from '@/core/api';
import { Viewer } from '@/core/viewer';
import RequestDetails from '@/expertrequest/components/ExpertRequestForm/DetailsNew';
import { useApp, usePermissions } from '@/hooks/useAppContext';
import NotFoundPage from '@/pages/NotFoundPage';
import { RootState } from '@/store';
import { SCREEN_SM } from '@/theme/screens';
import { formatDateTime } from '@/utils';
import { DocumentNodeResult } from '@/utils/gql';

import s from './Consultation.module.scss';
import Dialogs from './Dialogs';
import Header from './Header/Header';
import JoinCall from './JoinCall';
import Transcript from './Transcript';
import Metrics from './metrics/Metrics';
import CallDetails from './sections/CallDetails';
import ExpertRequestDetails from './sections/ExpertRequestDetails';
import FeedbackDetails from './sections/FeedbackDetails';
import WrittenResponse from './sections/WrittenResponse';

const userNotAvailable = '(User not available)';

function getExpertName(c: Consultation) {
  return c.expert?.name || c.expert_name || userNotAvailable;
}

function getUserName(viewer: Viewer, consultation: Consultation) {
  const { expert } = consultation;
  const isViewerExpert = viewer.id === expert?.id;
  return isViewerExpert ? consultation.requester_name : getExpertName(consultation);
}

function userAbleToSelectTime(viewer: Viewer, consultation: Consultation) {
  const { expert, requester, state } = consultation;
  return (
    state === 'negotiating_expert_time' &&
    ((expert?.id === viewer.id && !!expert?.compliance_completed_at) || requester?.id === viewer.id)
  );
}

export type Section =
  | 'call_details'
  | 'response_details'
  | 'request'
  | 'transcript'
  | 'feedback'
  | 'metrics'
  | undefined;

interface ConsultationProps {
  id: string;
  hire?: boolean;
  section?: Section;
  initialUsefulnessRating: number | undefined;
  initialMatchExperienceRating: number | undefined;
  openCompleteTraining?: boolean;
  openReviewCall?: boolean;
  openRequestTranscript?: boolean;
  timeChosen?: any;
}

const connector = connect(
  (state: RootState) => {
    return {
      viewer: state.viewer,
      call: state.call,
      userContext: state.ui.userContext,
      reviewsDismissedPermanently: state.consultationReviews.dismissedPermanently,
    };
  },
  {
    addRegistrant,
    deleteConsultation,
    dismissConsultationReview,
    hideMessage,
    inviteUser,
    getJoinInfo,
    notify,
    orderTranscript,
    updateConsultation,
    track,
  }
);

const GET_CONSULTATION = gengql(/* GraphQL */ `
  query getConsultation($id: String!) {
    consultation(id: $id) {
      id
      html_url
      type
      description
      starts_at
      proposed_times
      rejected_times
      expected_duration
      initial_expected_duration
      billing_duration
      started_at
      ended_at
      created_at
      credit_rate
      credit_amount
      disclosure
      summary
      costs {
        id
        state
        charge_type
        credits {
          cents
          currency
        }
      }
      bill_rate
      expert_payout
      estimated_expert_payout
      completion_adds_expert_to_network
      engagement_type
      state
      archived
      expert_identifier
      requester_identifier
      cancel_reason
      external
      recording_url
      recording_duration
      original_recording_url
      tracking_code
      conference {
        id
        carrier
        phone_numbers {
          display
          number
          country_code
        }
        duration
        registrants {
          email
          name
          identifier
          invited_by
        }
        attachments {
          file_type
          location
          recording_type
        }
        recording_state
      }
      requester {
        id
        username
        first_name
        last_name
        name
        html_url
        picture_url
        timezone
        phone
      }
      requester_timezone
      requester_name
      expert {
        id
        username
        first_name
        last_name
        name
        html_url
        picture_url
        timezone
        phone
        expert_state
        compliance_completed_at
        bill_rate
      }
      expert_timezone
      expert_name
      expert_request {
        id
        er_type
        html_url
        name
        description
        group_about
        phone
        focus_areas
        qualifications {
          id
          query
          response_type
          required
        }
        questions {
          id
          query
          response_type
          required
        }
        attachments {
          id
          created_at
          author {
            id
            name
            html_url
          }
          expert_request_id
          consultation_id
          name
          description
          file_url
        }
        companies_pursue
        companies_avoid
        disclosure
        regions {
          id
          name
        }
        sectors {
          id
          name
        }
        project {
          id
          name
          html_url
          tracking_code
          group {
            id
            name
            billing_account {
              id
              allow_hire_long_term
            }
          }
        }
        unpaid
        instructions_research
        job_scope
        opportunity_location
        tags
        expected_duration
      }
      group {
        id
        name
        account_type
      }
      client_review {
        id
        match_experience_rating
        communication_rating
        connection_quality_rating
        review
        target_feedback
        onfrontiers_feedback
      }
      expert_review {
        id
        uselfulness_rating
        communication_rating
        connection_quality_rating
        review
        target_feedback
        onfrontiers_feedback
        expert_usefulness_rating
        time_savings_feedback
        improved_bid_confidence_feedback
        partner_identification_feedback
        cost_savings_feedback
        new_opportunity_identification_feedback
        other_feedback
        other_text_feedback
      }
      event {
        description
        summary
        location
        start
        end
      }
      transcription_order {
        id
        state
      }
      transcription_price {
        cents
        currency
      }
      transcription {
        speakers {
          id
          name
          user {
            id
            first_name
            last_name
            picture_url
            html_url
          }
        }
        monologues {
          id
          speaker {
            id
            name
            user {
              id
              first_name
              last_name
              picture_url
              html_url
            }
          }
          elements {
            type
            value
            position
          }
        }
        summary
      }
      permissions
      attachments {
        id
        created_at
        author {
          id
          name
          html_url
        }
        expert_request_id
        consultation_id
        name
        description
        file_url
      }
    }
  }
`);

export type Consultation = NonNullable<DocumentNodeResult<typeof GET_CONSULTATION>['consultation']>;

const ConsultationPage: FC<ConsultationProps & ConnectedProps<typeof connector>> = ({
  viewer,
  id,
  hire = false,
  getJoinInfo,
  hideMessage,
  notify,
  call,
  orderTranscript,
  updateConsultation,
  deleteConsultation,
  userContext,
  section: initialSection,
  inviteUser,
  addRegistrant,
  dismissConsultationReview,
  initialUsefulnessRating,
  initialMatchExperienceRating,
  openCompleteTraining: initialOpenCompleteTraining = false,
  openReviewCall: initialOpenReviewCall = false,
  openRequestTranscript: initialOpenRequestTranscript = false,
  timeChosen,
  track,
  reviewsDismissedPermanently,
}) => {
  const navigate = useNavigate();
  const { store } = useApp();

  const [canViewArchived] = usePermissions(
    [{ service: 'consultation', action: 'view_archived', resource: id }],
    [id]
  );

  const { loading, error, data } = useQuery(GET_CONSULTATION, { variables: { id } });
  const consultation = data?.consultation;

  useEffect(() => {
    if (consultation?.archived && !canViewArchived) {
      track('promo.show.consultation.archived');
    }
  }, [canViewArchived, consultation?.archived, track]);

  const section =
    initialSection ||
    (consultation?.engagement_type === EngagementType.WrittenResponse
      ? 'response_details'
      : 'call_details');

  const {
    expert_request: expertRequest,
    requester,
    expert,
    state,
    client_review: clientReview,
    expert_review: expertReview,
    transcription,
  } = consultation || {};

  const hasCompletedComplianceTraining = expert && !!expert.compliance_completed_at;

  const endedAt = consultation?.ended_at && moment(consultation.ended_at);

  const isViewerExpert = viewer.id === expert?.id;
  const isViewerClient = viewer.id === requester?.id;
  const hasReview = !!(isViewerExpert
    ? clientReview && clientReview.id
    : expertReview && expertReview.id);
  const withinOneMonth = endedAt && endedAt.add(30, 'd').isAfter(moment());
  const shouldReview =
    !consultation?.external &&
    consultation?.state === 'completed' &&
    !hasReview &&
    withinOneMonth &&
    (isViewerClient || isViewerExpert) &&
    !reviewsDismissedPermanently.some((d: any) => d === id);

  const initialSuggestTime =
    timeChosen === 'none' && !!consultation && userAbleToSelectTime(viewer, consultation);

  const [openDenyDialog, setOpenDenyDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSuggestTime, setOpenSuggestTime] = useState(initialSuggestTime);
  const [openCompleteTraining, setOpenCompleteTraining] = useState(
    initialOpenCompleteTraining && isViewerExpert && !hasCompletedComplianceTraining
  );
  const [openReviewCall, setOpenReviewCall] = useState(initialOpenReviewCall || shouldReview);
  const [openRequestTranscript, setOpenRequestTranscript] = useState(initialOpenRequestTranscript);
  const [openInviteParticipant, setOpenInviteParticipant] = useState(false);
  const [date, setDate] = useState(timeChosen);
  const [submittingTranscript, setSubmittingTranscript] = useState(false);
  const [joinInfo, setJoinInfo] = useState<JoinInfo | undefined>(undefined);

  const conference = consultation?.conference;

  const handleUpdateJoinInfo = useCallback(async () => {
    if (conference?.carrier === 'zoom' && conference?.id) {
      try {
        setJoinInfo(await getJoinInfo(conference.id));
      } catch (e: any) {
        if (hasErrorCode(e, ERROR_CODES.CONFERENCE_HOST_CONFERENCE_NOT_FOUND)) {
          console.warn(e);
          return;
        }
        throw e;
      }
    }
  }, [conference?.carrier, conference?.id, getJoinInfo]);

  const showCompleteTraining = useCallback(
    (emptyDate: any) => {
      const dateSelected = date || consultation?.proposed_times[0];

      setOpenCompleteTraining(true);
      setDate(emptyDate ? undefined : dateSelected);
    },
    [consultation, date]
  );

  const handleConfirm = useCallback(() => {
    setOpenConfirmDialog(false);
    hideMessage();

    if (!date) {
      return notify('You need to select a suggested time.', 'error');
    }

    const userName = consultation && getUserName(viewer, consultation);

    updateConsultation({
      id: consultation?.id,
      starts_at: date,
      state: 'confirmed',
      trigger: Trigger.consultationPage,
    }).then(
      () => {
        notify(
          `You accepted ${userName}'s suggested call time of ${formatDateTime(
            date,
            viewer.timezone || undefined
          )}.`,
          'success'
        );
        handleUpdateJoinInfo();
      },
      (err: any) => {
        if (err.message === OUTDATED_ERROR) {
          notify('Cannot schedule the event for a past date.', 'error');
        } else if (err.message === ALREADY_CONFIRMED) {
          notify('Consultation already confirmed.', 'error');
        } else if (err.message === ALREADY_CANCELED) {
          notify('Consultation already canceled.', 'error');
        } else {
          notify('An error occurred when accepting the consultation.', 'error');
        }
      }
    );
  }, [consultation, viewer, date, hideMessage, notify, handleUpdateJoinInfo, updateConsultation]);

  const handleAccept = useCallback(() => {
    const hasCompletedComplianceTraining = expert && !!expert.compliance_completed_at;

    if (isViewerExpert) {
      if (!hasCompletedComplianceTraining) {
        setOpenCompleteTraining(true);
        return;
      }
    }

    return handleConfirm();
  }, [expert, isViewerExpert, handleConfirm]);

  const handleDeny = useCallback(
    (values: any) => {
      hideMessage();

      setOpenDenyDialog(false);
      setDate(null);

      updateConsultation({
        id: consultation?.id,
        state: 'denied',
        cancel_reason: values.cancel_reason || '',
        trigger: Trigger.consultationPage,
      }).catch(() => {
        notify('An error occurred when canceling consultation.', 'error');
      });
    },
    [consultation, hideMessage, notify, updateConsultation]
  );

  const handleDelete = useCallback(() => {
    deleteConsultation(consultation?.id)
      .then(() => {
        navigate('/consultations');
      })
      .catch((err: any) => {
        notify('Error when deleting consultation', 'error');
        throw err;
      });
  }, [consultation?.id, deleteConsultation, navigate, notify]);

  const handleCancel = useCallback(
    (values: any) => {
      hideMessage();

      setOpenCancelDialog(false);
      setDate(null);

      updateConsultation({
        id: consultation?.id,
        state: 'canceled',
        cancel_reason: values.cancel_reason || '',
        trigger: Trigger.consultationPage,
      }).catch((err: any) => {
        const message =
          err && err.message === ALREADY_STARTED
            ? 'Cannot cancel ongoing consultation.'
            : 'An error occurred when canceling consultation.';
        notify(message, 'error');
      });
    },
    [consultation, hideMessage, notify, updateConsultation]
  );

  const handleInviteParticipant = useCallback(
    async (values: any) => {
      setOpenInviteParticipant(false);

      const conferenceId = conference?.id;
      if (!conferenceId) {
        notify('Error inviting participant. No conference', 'error');
        return;
      }

      try {
        await addRegistrant(consultation, {
          conferenceId,
          name: values.name,
          email: values.email,
          invited_by: viewer.name,
        });

        if (values.inviteJoinTeam) {
          const groupId =
            consultation?.group?.id || consultation?.expert_request?.project?.group?.id;
          if (!groupId) {
            notify('Invited participant but error inviting to group', 'error');
            return;
          }
          await inviteUser({
            email: values.email,
            collectionType: 'group',
            collectionId: groupId,
            role: 'member',
          });
        }
        notify('Successfully invited participant');
      } catch (ex) {
        notify('Error inviting participant', 'error');
        throw ex;
      }
    },
    [conference?.id, notify, addRegistrant, consultation, viewer.name, inviteUser]
  );

  const handleSuggestTime = useCallback(
    (values: any) => {
      if (!values || !values.dates) return;

      setOpenSuggestTime(false);
      setDate(null);

      const userName = consultation && getUserName(viewer, consultation);

      updateConsultation({
        id: consultation?.id,
        proposed_times: values.dates.filter(Boolean),
        duration: values.duration,
        state: isViewerExpert ? 'negotiating_client_time' : 'negotiating_expert_time',
        trigger: Trigger.consultationPage,
      })
        .then(() => {
          notify(`Sent time suggestion to ${userName}.`, 'success');
        })
        .catch((err: any) => {
          const message =
            err && err.message === ALREADY_STARTED
              ? 'Cannot reschedule ongoing consultation.'
              : err && err.message === NOT_ENOUGH_CREDITS
                ? 'Not enough credits to reschedule the consultation.'
                : 'An error occurred when rescheduling consultation.';
          notify(message, 'error');
        });
    },
    [viewer, consultation, updateConsultation, isViewerExpert, notify]
  );

  const handleRequestTranscript = useCallback(() => {
    hideMessage();
    const { id, transcription_price: transcriptionPrice } = consultation || {};

    setSubmittingTranscript(true);

    orderTranscript(id, transcriptionPrice)
      .then(() => {
        notify('Transcript requested.', 'success');
        setOpenRequestTranscript(false);
        setSubmittingTranscript(false);
      })
      .catch((e: any) => {
        setSubmittingTranscript(false);

        if (e.message === 'GraphQL Error: maximum amount of credits not provided') {
          notify(
            'This consultation has an invalid duration and ' +
              'cannot be transcribed at this time. ' +
              'A member of the engineering team has been notified.',
            'error'
          );
          return;
        }

        if (e.message === 'GraphQL Error: not enough credits to order transcription') {
          notify('Not enough credits to order transcript', 'error');
          return;
        }

        notify('An error occurred when requesting the transcript.', 'error');
      });
  }, [consultation, hideMessage, notify, orderTranscript]);

  const handleOpenSuggestTime = useCallback(() => {
    const { expert } = consultation || {};
    const hasCompletedComplianceTraining = expert && !!expert.compliance_completed_at;

    if (isViewerExpert && !hasCompletedComplianceTraining) {
      setOpenCompleteTraining(true);
    } else {
      setOpenSuggestTime(true);
    }
  }, [consultation, isViewerExpert]);

  useEffect(() => {
    if (openSuggestTime) handleOpenSuggestTime();
    handleUpdateJoinInfo();

    async function fetchRequests() {
      // @ts-expect-error fetch does not exist on RequestDetails
      await Promise.all(RequestDetails.fetch.map((f: any) => store.dispatch(f({ viewer }))));
    }
    fetchRequests();
  }, [openSuggestTime, handleUpdateJoinInfo, handleOpenSuggestTime, store, viewer]);

  const handleOpenTranscript = () => {
    setOpenRequestTranscript(true);
  };

  const dismissReview = () => {
    dismissConsultationReview(consultation?.id, true);
    setOpenReviewCall(false);
  };

  const closeDialogs = () => {
    setOpenDenyDialog(false);
    setOpenCompleteTraining(false);
    setOpenConfirmDialog(false);
    setOpenCancelDialog(false);
    setOpenSuggestTime(false);
    setOpenReviewCall(false);
    setOpenRequestTranscript(false);
    setOpenInviteParticipant(false);
  };

  if (hasErrorCode(error, ERROR_CODES.CONSULTATION_NOT_FOUND)) {
    return <NotFoundPage />;
  }

  if (loading || !consultation) {
    return (
      <MediaQuery maxWidth={SCREEN_SM}>
        {(isMobileVersion: boolean) => {
          return (
            <LayoutPage
              showNav
              selected="consultations"
              footerStyle={{
                paddingBottom: isMobileVersion ? 110 : 0,
              }}
              showReviewConsultation={!openReviewCall}
            >
              <Helmet>
                <title>Consultation</title>
              </Helmet>
              {loading && (
                <div className={s.loading}>
                  <CircularProgress />
                </div>
              )}
              {error && <span>Error loading consultation.</span>}
            </LayoutPage>
          );
        }}
      </MediaQuery>
    );
  }

  const requesterName = consultation.requester_name;
  const expertName = getExpertName(consultation);

  const isExpertActive = expert?.expert_state === 'active';
  const isViewerRequester = viewer.id === requester?.id;
  const isViewerAdmin = !!viewer.admin && !isViewerExpert && !isViewerRequester;
  const user = isViewerExpert ? requester : expert;

  const isExpired = state === 'expired';
  const isCompleted = state === 'completed';
  const isConfirmed = state === 'confirmed';
  const isCanceled = state === 'canceled';
  const isDenied = state === 'denied';
  const isIncomplete = state === 'incomplete';

  const isWaitingExpertConfirmation = state === 'negotiating_expert_time';
  const isWaitingRequesterConfirmation = state === 'negotiating_client_time';
  const isWaitingConfirmation = isWaitingExpertConfirmation || isWaitingRequesterConfirmation;
  const isWaitingViewerConfirmation =
    (isViewerExpert && isWaitingExpertConfirmation) ||
    (!isViewerExpert && isWaitingRequesterConfirmation);

  const isWrittenConsultation = consultation?.engagement_type === EngagementType.WrittenResponse;
  const showDetails = isConfirmed || isCompleted || isCanceled || isIncomplete;
  const canEdit = isViewerRequester || isViewerExpert || isViewerAdmin;
  const canConfirm = isExpertActive && isWaitingViewerConfirmation && canEdit;
  const canCancel =
    isExpertActive && (isConfirmed || (!isViewerExpert && isWaitingConfirmation)) && canEdit;
  const canDeny = isExpertActive && isViewerExpert && isWaitingConfirmation;
  const canReschedule = isExpertActive && (isConfirmed || isExpired || isIncomplete);
  const canHireExpert =
    consultation?.expert_request?.project?.group?.billing_account?.allow_hire_long_term;

  const baseProps = {
    consultation,
    expertRequest,
    requesterName: requesterName || userNotAvailable,
    expertName,
    isViewerExpert,
    isExpertActive,
    isViewerRequester,
    isViewerAdmin,
    user,
    isExpired,
    isCompleted,
    isConfirmed,
    isDenied,
    isIncomplete,
    isWaitingConfirmation,
    isWaitingExpertConfirmation,
    isWaitingRequesterConfirmation,
    isWaitingViewerConfirmation,
    isWrittenConsultation,
    canEdit,
    canConfirm,
    canCancel,
    canDeny,
    canReschedule,
    canHireExpert,
    shouldReview,
    hasReview,
  };

  const showRequest = !!expertRequest;
  const showTranscription =
    !isWrittenConsultation &&
    !isViewerExpert &&
    transcription &&
    transcription.monologues &&
    transcription.monologues.length > 0;
  const showFeedback = hasReview;

  const showMetrics =
    !isWrittenConsultation &&
    conference?.carrier &&
    viewer.admin &&
    state &&
    [
      ConsultationState.Confirmed,
      ConsultationState.InProgress,
      ConsultationState.Finalizing,
      ConsultationState.Completed,
      ConsultationState.Incomplete,
    ].includes(state);
  const showTabs = showRequest || showTranscription || showFeedback || showMetrics;

  if (
    section === 'metrics' &&
    (!viewer.admin ||
      !conference ||
      !['confirmed', 'in_progress', 'finalizing', 'completed', 'incomplete'].includes(
        consultation.state
      ))
  ) {
    return <NotFoundPage />;
  }

  if (consultation?.archived && !canViewArchived) {
    return (
      <>
        <Helmet>
          <title>Consultation Details</title>
        </Helmet>
        <InaccessibleArchived selectedTab="consultations" entity="consultation" />
      </>
    );
  }

  return (
    <MediaQuery maxWidth={SCREEN_SM}>
      {(isMobileVersion: boolean) => {
        const CallActions = !isWrittenConsultation && (
          <JoinCall consultation={consultation} joinInfo={joinInfo} />
        );

        return (
          <LayoutPage
            showNav
            selected="consultations"
            footerStyle={{
              paddingBottom: isMobileVersion && CallActions ? 110 : 0,
            }}
            showReviewConsultation={!openReviewCall}
          >
            <Helmet>
              <title>{`Consultation with ${expert ? expert.name : 'Expert'}`}</title>
            </Helmet>

            {viewer.admin && expertRequest && (
              <ProjectBar viewer={viewer} project={expertRequest?.project} />
            )}

            <Header
              {...baseProps}
              canHireExpert={true}
              hire={hire}
              expertId={expert?.id || ''}
              isMobileVersion={isMobileVersion}
              callActions={CallActions}
              onReviewCall={() => setOpenReviewCall(true)}
              onDelete={consultation.external ? handleDelete : undefined}
              shouldReview={shouldReview}
            />

            <Divider spacing={30} />

            {showTabs && (
              <Tabs value={section} style={{ marginBottom: 30 }}>
                <Tab
                  onClick={() => navigate(`/consultation/${id}`)}
                  value={isWrittenConsultation ? 'response_details' : 'call_details'}
                  label={isWrittenConsultation ? 'Response Details' : 'Call Details'}
                />
                {showRequest && (
                  <Tab
                    onClick={() => navigate(`/consultation/${id}/request`)}
                    value="request"
                    label="Request Details"
                  />
                )}
                {showTranscription && (
                  <Tab
                    onClick={() => navigate(`/consultation/${id}/transcript`)}
                    value="transcript"
                    label="Transcript"
                  />
                )}
                {showFeedback && (
                  <Tab
                    onClick={() => navigate(`/consultation/${id}/feedback`)}
                    value="feedback"
                    label="Feedback"
                  />
                )}
                {showMetrics && (
                  <Tab
                    onClick={() => navigate(`/consultation/${id}/metrics`)}
                    value="metrics"
                    label="Metrics"
                  />
                )}
              </Tabs>
            )}

            <div className={s.consultationContent}>
              {section === 'call_details' && (
                <CallDetails
                  {...baseProps}
                  viewer={viewer}
                  userContext={userContext}
                  joinInfo={joinInfo}
                  showDetails={showDetails}
                  isMobileVersion={isMobileVersion}
                  ongoingCall={call.connected}
                  openReviewCall={openReviewCall}
                  onRequestTranscript={handleOpenTranscript}
                  selectedDate={date}
                  onOpenSuggestTime={handleOpenSuggestTime}
                  onDateSelect={setDate}
                  onConfirm={handleAccept}
                  onCancel={() => setOpenCancelDialog(true)}
                  onDeny={() => setOpenDenyDialog(true)}
                  onReschedule={() => setOpenSuggestTime(true)}
                  onInviteParticipant={() => setOpenInviteParticipant(true)}
                  transcription={transcription}
                />
              )}
              {section === 'response_details' && (
                <WrittenResponse
                  {...baseProps}
                  viewer={viewer}
                  isMobileVersion={isMobileVersion}
                  showCompleteTraining={showCompleteTraining}
                />
              )}
              {section === 'request' && (
                <ExpertRequestDetails
                  consultation={consultation}
                  isViewerExpert={isViewerExpert}
                  isMobileVersion={isMobileVersion}
                />
              )}
              {!isViewerExpert && section === 'transcript' && (
                <Transcript transcription={transcription} expertId={expert?.id} />
              )}
              {showFeedback && section === 'feedback' && (
                <FeedbackDetails
                  clientReview={clientReview}
                  expertReview={expertReview}
                  isViewerExpert={isViewerExpert}
                  isViewerRequester={isViewerRequester}
                  requester={requester}
                  expert={expert}
                />
              )}
              {showMetrics && section === 'metrics' && <Metrics consultationId={id} />}
            </div>

            {isMobileVersion && CallActions && (
              <div className={s.stickyCallActions}>{CallActions}</div>
            )}

            <Dialogs
              user={user}
              consultation={consultation}
              date={date}
              viewer={viewer}
              isViewerExpert={isViewerExpert}
              onDismissReview={dismissReview}
              onCloseDialog={closeDialogs}
              onDeny={handleDeny}
              onCancel={handleCancel}
              onConfirm={handleConfirm}
              onSuggestTime={handleSuggestTime}
              onRequestTranscript={handleRequestTranscript}
              onInviteParticipant={handleInviteParticipant}
              openDenyDialog={openDenyDialog}
              openConfirmDialog={openConfirmDialog}
              openCancelDialog={openCancelDialog}
              openCompleteTraining={openCompleteTraining}
              openReviewCall={openReviewCall}
              openSuggestTime={openSuggestTime}
              openRequestTranscript={openRequestTranscript}
              openInviteParticipant={openInviteParticipant}
              submittingTranscript={submittingTranscript}
              initialUsefulnessRating={initialUsefulnessRating}
              initialMatchExperienceRating={initialMatchExperienceRating}
              shouldReview={shouldReview}
              isWrittenConsultation={isWrittenConsultation}
            />
          </LayoutPage>
        );
      }}
    </MediaQuery>
  );
};

export default connector(ConsultationPage);
