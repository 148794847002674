import { useApolloClient } from '@apollo/client';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import Form from '@/components/Form';
import { TextField } from '@/components/FormAdapters';
import { updateProfile } from '@/profile/store';

function AddAdditionalInformationForm({
  userId,
  profileId,
  updateProfile,
  onSubmit,
  reset,
  onReset,
  handleSubmit: handleSubmitProp,
  component: Container = Form,
  ...rest
}: any) {
  const client = useApolloClient();
  const handleSubmit = async (values: any) => {
    await updateProfile({ id: profileId, ...values });
    // Temporarily until we migrate out of redux, this should be part of the mutation
    client.refetchQueries({ include: ['fetchActivities'] });
    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleReset = () => {
    reset();

    if (onReset) {
      onReset();
    }
  };

  return (
    <Container {...rest} onSubmit={handleSubmitProp(handleSubmit)} onReset={handleReset}>
      <Field
        multiline
        id="additionalInformation"
        component={TextField}
        name="additional_information"
        fullWidth
        autoFocus
        minRows={3}
        maxRows={15}
        inputProps={{ maxLength: 10000 }}
      />
    </Container>
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'AddAdditionalInformationForm' be... Remove this comment to see the full error message
AddAdditionalInformationForm = reduxForm({
  form: 'additionalInformation',
  enableReinitialize: true,
})(AddAdditionalInformationForm);

// @ts-expect-error TS(2630): Cannot assign to 'AddAdditionalInformationForm' be... Remove this comment to see the full error message
AddAdditionalInformationForm = connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        additional_information: profile.additional_information,
      },
    };
  },
  {
    updateProfile,
  }
)(AddAdditionalInformationForm);

export default AddAdditionalInformationForm;
