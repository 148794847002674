import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import { gengql } from '@/__generated__';
import Dialog from '@/components/Dialog';

const HIRE_EXPERT = gengql(/* GraphQL */ `
  mutation hireExpert($consultationId: String) {
    requestHireExpertLongTerm(consultation_id: $consultationId)
  }
`);

interface HireConfirmationDialogProps {
  consultationId: string;
  open: boolean;
  onClose: () => void;
}

export const HireConfirmationDialog = ({
  consultationId,
  open,
  onClose,
}: HireConfirmationDialogProps): JSX.Element => {
  const [hireExpert] = useMutation(HIRE_EXPERT);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const handleProceed = useCallback(async () => {
    const { data: success } = await hireExpert({
      variables: { consultationId },
    });

    console.log('HireExpert success:', success);

    setConfirmationModalOpen(true);
    onClose();
  }, [onClose, consultationId, hireExpert]);

  return (
    <>
      <Dialog
        open={open}
        title="Hire Expert for Ongoing Engagement"
        subTitle={
          <>
            <p>
              You are about to notify us of your interest in working with this expert for a long
              ongoing engagement.
            </p>
            <p>
              You will <strong>not</strong> be charged until we work out the details with you.
            </p>
            <p>Are you sure you want to proceed?</p>
          </>
        }
        confirmLabel="Yes. Proceed"
        cancelLabel="No. Cancel"
        onConfirm={handleProceed}
        onCancel={onClose}
      />
      <Dialog
        open={confirmationModalOpen}
        title="Thank you!"
        subTitle={
          <>
            <p>A member of our team will reach out to you.</p>
            <p>
              You will <strong>not</strong> be charged until we work out the details with you.
            </p>
          </>
        }
        confirmLabel="Close"
        onConfirm={() => setConfirmationModalOpen(false)}
      />
    </>
  );
};
