import moment, { Moment } from 'moment-timezone';

import { EngagementType } from '@/__generated__/graphql';
import { notify } from '@/actions/ui';
import { Trigger, updateConsultation } from '@/consultation/store';
import NotFoundPage from '@/pages/NotFoundPage';
import { LegacyRoute, parseQueryBool, parseQueryInt } from '@/routes/routesMiddleware';
import { AppStore } from '@/store';
import { formatDate, formatDateTime } from '@/utils';

import Consultation, { Section } from '../components/Consultation';

const route: LegacyRoute = {
  path: '/consultation/:id/:section?',

  async action({ store, params, query }) {
    const { section } = params;
    const id = params.id!;

    const timeChosen = query.get('confirm_time');
    const token = query.get('token');
    const reviewCall = parseQueryBool(query, 'review_call');
    const hire = parseQueryBool(query, 'hire');
    const requestTranscript = parseQueryBool(query, 'request_transcript');
    const matchExperience = parseQueryInt(query, 'match_experience');
    const usefulness = parseQueryInt(query, 'usefulness');
    //const joinCall = parseQueryBool(query, 'join_call');

    if (section && !['request', 'transcript', 'feedback', 'metrics'].includes(section))
      return <NotFoundPage />;

    if (timeChosen && timeChosen !== 'none') {
      await confirmTime(store, id, moment(timeChosen));
    }

    return (
      <Consultation
        id={id}
        hire={hire}
        section={section as Section}
        openCompleteTraining={!!token}
        openReviewCall={reviewCall}
        openRequestTranscript={requestTranscript}
        initialMatchExperienceRating={matchExperience}
        initialUsefulnessRating={usefulness}
        //joinCall={joinCall}
        timeChosen={timeChosen}
      />
    );
  },
};

async function confirmTime(store: AppStore, consultationId: string, time: Moment) {
  const { viewer } = store.getState();

  try {
    const consultation = await store.dispatch(
      updateConsultation({
        id: consultationId,
        starts_at: time.toISOString(),
        state: 'confirmed',
        trigger: Trigger.emailLink,
      })
    );
    const successMessage =
      consultation.engagement_type === EngagementType.WrittenResponse
        ? `You accepted proposed deadline of ${formatDate(time, viewer.timezone || undefined)}.`
        : `You accepted suggested call time of ${formatDateTime(time, viewer.timezone || undefined)}.`;

    await store.dispatch(notify(successMessage, 'success'));
  } catch {
    await store.dispatch(notify('An error occurred when accepting the consultation.', 'error'));
  }
}

export default route;
